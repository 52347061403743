import { Fetcher } from '@this/src/util';
/* eslint-disable max-lines */
import React from 'react';
import _ from 'lodash';
import { styled } from '@this/constants/themes';
import { withTheme } from 'styled-components';
import { ButtonBase, ButtonType } from '@this/components/shared/atoms/button';
import ContentBody, { ContentBodyIn } from '@this/components/shared/atoms/content_body';
import A from '@this/components/shared/atoms/a';
import TranslateIgnoreText from '@this/components/shared/text/translate_ignore_text';

import SimpleLoading from '@this/components/shared/simple_loading/simple_loading';
import ContentHeader from '@this/components/shared/content_header/content_header';
import { Loading } from '@this/components/shared/ui/feedbacks/loading';

import MarketLogHelper from '@this/domain/market_log/market_log_helper';
import type ElementBase from '@this/domain/element_base';
import ProjectList from '@this/domain/project/project_list';
import ProjectShareList from '@this/domain/project/project_share_list';
import type { ProjectShareArgs } from '@this/domain/project/project_share';
import DepartmentList from '@this/src/domain/department/department_list';
import ChargingDepartmentShareList from '@this/src/domain/department/charging_department_share_list';
import type { ChargingDepartmentShareArgs } from '@this/domain/department/charging_department_share';
import { ExpensesAccountType } from '@this/domain/expenses/expenses_account_type';
import type { ExpensesAccountTypeJson } from '@this/domain/expenses/expenses_account_type';
import type { InAdvanceApprovalArgs } from '@this/domain/in_advance_approval';
import { InAdvanceApproval } from '@this/domain/in_advance_approval';

import moment from '@this/lib/moment';
import Trip from '@this/domain/trip/trip';
import { lighten, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { media } from '@this/shared/atoms/media';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { InfoOutlined } from '@material-ui/icons';
import TripRuleSuccessSection from '@this/components/reserve_trip/reserve_confirm/routings/input_customer_information/trip_rule_section/trip_rule_success_section';
import {
  DescriptionButton,
  ReserveErrorAlert,
  ReserveSuccessAlert,
  StyledAlertTitle
} from '@this/components/reserve_trip/reserve_confirm/routings/input_customer_information/input_customer_information.template';
import type TripRuleError from '@this/domain/trip/trip_rule_error';
import TripRuleErrorSection from '@this/components/reserve_trip/reserve_confirm/routings/input_customer_information/trip_rule_section/trip_rule_error_section';
import { getColor } from '@this/shared/ui/theme';
import TripDuplicationSection from '@this/components/reserve_trip/reserve_confirm/routings/input_customer_information/trip_duplication_section/trip_duplication_section';
import type { TripDuplicationInterface } from '@this/components/reserve_trip/reserve_confirm/reserve_confirm';
import type Department from '@this/domain/department/department';
import type NonOrderItemMapping from '@this/domain/non_order_item_mapping';
import { Flex } from '@this/shared/ui/layout/flex';
import type TripRuleDistanceTimeInfo from '@this/domain/trip/trip_rule_distance_time_info';
import { reportError } from '@this/lib/bugsnag';
import InAdvanceApplicationTripDetail from './in_advance_application_trip_detail';

interface Props {
  theme: { serviceName: string };
  approved?: boolean;
}

interface DomesticAirTicketInfo {
  status: 'success' | 'invalid' | 'loading';
  priceDiff: number;
  transportVacancies: {
    transportId: number;
    price: number;
    adultprice: number;
    priceDiff: number;
    seatnum: string;
    occupied: boolean;
  }[];
}

interface DomesticAirTicketInfoResult {
  status: 'success' | 'invalid' | 'loading';
  price_diff: number;
  transport_vacancies: {
    transport_id: number;
    price: number;
    adultprice: number;
    price_diff: number;
    seatnum: string;
    occupied: boolean;
  }[];
}

export interface State {
  trip: any;
  loading: boolean;
  error: string | null;
  hotelError: boolean;
  transportError: boolean;
  shinkansenTooLateError: boolean;
  shinkansenDeadline: string | null;
  domesticAirTooLateError: boolean;
  domesticAirDeadline: string | null;
  domesticAirCheckTicket: boolean;
  foreignAirTooLateError: boolean;
  foreignAirDeadline: string | null;
  packageTooLateError: boolean;
  packageDeadline: string | null;
  rentalCarTooLateError: boolean;
  rentalCarDeadline: string | null;
  hotelTooLateError: boolean;
  hotelDeadline: string | null;
  budgetInfo: any;
  currentButtonArea: string | null;
  approvalSubmitting: boolean;
  approvalError: string | null;
  rejectSubmitting: boolean;
  rejectReason: string;
  approveReason: string;
  rejectError: string | null;
  approveItems: [];
  shinkansenAddressInfo: any;
  projects: ProjectList;
  projectShares: ProjectShareList;
  departments: DepartmentList;
  departmentShares: ChargingDepartmentShareList;
  expensesAccountTypes: ExpensesAccountType[];
  expensesAccountTypeAvailable: boolean;
  domesticAirTicketInfo: DomesticAirTicketInfo;
  tripDuplications: TripDuplicationInterface[];
  showAlert: boolean;
  inAdvanceApproval: InAdvanceApproval[];
}

interface FetchDetailTripResult {
  user: any;
  trip: any;
  show_fee: boolean;
  hotel_error: boolean;
  transport_error: boolean;
  shinkansen_too_late_error: boolean;
  shinkansen_deadline: string | null;
  domestic_air_too_late_error: boolean;
  domestic_air_deadline: string | null;
  domestic_air_check_ticket: boolean;
  foreign_air_too_late_error: boolean;
  foreign_air_deadline: string | null;
  package_too_late_error: boolean;
  package_deadline: string | null;
  rental_car_too_late_error: boolean;
  rental_car_deadline: string | null;
  hotel_too_late_error: boolean;
  hotel_deadline: string | null;
  car_type_options: any[];
  budget_info: any;
  approve_items: string;
  shinkansen_address_info: any;
  projects: object[];
  project_shares: ProjectShareArgs[];
  departments: object[];
  charging_department_shares: ChargingDepartmentShareArgs[];
  expenses_account_types: ExpensesAccountTypeJson[];
  expenses_account_type_available: boolean;
  trip_distance_and_time_info: TripRuleDistanceTimeInfo[];
  trip_duplications: TripDuplicationInterface[];
}

class InAdvanceApplicationDetail extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      trip: null,
      loading: true,
      error: null,
      hotelError: false,
      transportError: false,
      shinkansenTooLateError: false,
      shinkansenDeadline: null,
      domesticAirTooLateError: false,
      domesticAirDeadline: null,
      domesticAirCheckTicket: false,
      foreignAirTooLateError: false,
      foreignAirDeadline: null,
      packageTooLateError: false,
      packageDeadline: null,
      rentalCarTooLateError: false,
      rentalCarDeadline: null,
      hotelTooLateError: false,
      hotelDeadline: null,
      budgetInfo: {},
      currentButtonArea: null,
      approvalSubmitting: false,
      approvalError: null,
      rejectSubmitting: false,
      rejectReason: '',
      approveReason: '',
      rejectError: null,
      approveItems: [],
      shinkansenAddressInfo: {},
      projects: new ProjectList([]),
      projectShares: new ProjectShareList([]),
      departments: new DepartmentList([], []),
      departmentShares: new ChargingDepartmentShareList([]),
      expensesAccountTypes: [],
      expensesAccountTypeAvailable: false,
      inAdvanceApproval: [],
      domesticAirTicketInfo: {
        status: 'success',
        priceDiff: 0,
        transportVacancies: []
      },
      tripDuplications: [],
      showAlert: false
    };
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    const id = _.last(location.href.split('/'));
    let trip = null;
    try {
      this.setState({ loading: true });

      const url = `/application/${this.props.approved ? 'approved' : 'in_advance'}/${id}.json`;
      const result = await Fetcher.get<FetchDetailTripResult>(url);
      trip = new Trip(
        _.merge(result.trip, {
          showFee: result.show_fee,
          currentUser: result.user,
          car_type_options: result.car_type_options,
          trip_distance_and_time_info: result.trip_distance_and_time_info
        })
      );
      this.setState({
        trip,
        hotelError: result.hotel_error,
        transportError: result.transport_error,
        shinkansenTooLateError: result.shinkansen_too_late_error,
        shinkansenDeadline: result.shinkansen_deadline,
        domesticAirTooLateError: result.domestic_air_too_late_error,
        domesticAirDeadline: result.domestic_air_deadline,
        domesticAirCheckTicket: result.domestic_air_check_ticket,
        foreignAirTooLateError: result.foreign_air_too_late_error,
        foreignAirDeadline: result.foreign_air_deadline,
        packageTooLateError: result.package_too_late_error,
        packageDeadline: result.package_deadline,
        rentalCarTooLateError: result.rental_car_too_late_error,
        rentalCarDeadline: result.rental_car_deadline,
        hotelTooLateError: result.hotel_too_late_error,
        hotelDeadline: result.hotel_deadline,
        budgetInfo: result.budget_info,
        currentButtonArea: InAdvanceApplicationDetail.fetchButtonArea(trip, this.props.approved),
        approveItems: JSON.parse(result.approve_items),
        shinkansenAddressInfo: result.shinkansen_address_info,
        projects: new ProjectList(result.projects || []),
        projectShares: new ProjectShareList(result.project_shares || []),
        departments: new DepartmentList(result.departments, []),
        departmentShares: new ChargingDepartmentShareList(result.charging_department_shares),
        expensesAccountTypeAvailable: result.expenses_account_type_available,
        expensesAccountTypes: result.expenses_account_types.map(account => new ExpensesAccountType(account)),
        inAdvanceApproval: result.trip?.in_advance_approval.map(
          (data: InAdvanceApprovalArgs) => new InAdvanceApproval(data)
        ),
        loading: false,
        domesticAirTicketInfo: {
          status: result.domestic_air_check_ticket ? 'loading' : 'success',
          priceDiff: 0,
          transportVacancies: []
        },
        tripDuplications: result.trip_duplications
      });
    } catch (error) {
      let errorMessage = '通信に失敗しました。しばらくしてから再度お試しください。';
      if (error.response.data) {
        if (error.response.data.error) {
          errorMessage = error.response.data.error;
        } else if (_.includes(error.response.data.errors, 'invalid approver')) {
          errorMessage =
            'こちらの申請情報のアクセス権がありません。\nサービスにログインしている、もしくは、ご自身がその申請情報の承認者として登録されているかご確認ください。';
        }
      }
      this.setState({
        loading: false,
        error: errorMessage
      });
    }

    if (this.state.domesticAirCheckTicket) {
      try {
        const result = await Fetcher.get<DomesticAirTicketInfoResult>(
          `/application/in_advance/${id}/check_domestic_air_ticket.json`
        );
        this.setState({
          domesticAirTicketInfo: {
            status: result.status,
            priceDiff: result.price_diff,
            transportVacancies: result.transport_vacancies.map(tv => ({
              transportId: tv.transport_id,
              price: tv.price,
              adultprice: tv.adultprice,
              priceDiff: tv.price_diff,
              seatnum: tv.seatnum,
              occupied: tv.occupied
            }))
          }
        });
      } catch {
        this.setState({
          domesticAirTicketInfo: {
            status: 'success',
            priceDiff: 0,
            transportVacancies: []
          }
        });
      }
    }
  }

  static fetchButtonArea(trip: any, approvedForm?: boolean) {
    switch (trip.approvalStatus(trip.currentApprover(trip.currentUser.id))) {
      case 1:
        return 'approved';
      case 2:
        return 'rejected';
      default:
        return approvedForm ? 'applied' : 'normal';
    }
  }

  handleApproveClick(e: React.MouseEvent<HTMLElement>) {
    e.preventDefault();
    this.setState({ currentButtonArea: 'approving' });
  }

  handleRejectClick(e: React.MouseEvent<HTMLElement>) {
    e.preventDefault();
    this.setState({ currentButtonArea: 'rejecting' });
  }

  handleSubmitApproval(e: React.MouseEvent<HTMLElement>) {
    e.preventDefault();
    this.setState({
      approvalSubmitting: true,
      approvalError: null
    });
    Fetcher.put<{ approve_reason: string }>(`/application/in_advance/${this.state.trip.id}/approve`, {
      approve_reason: this.state.approveReason
    }).then(
      res => {
        this.setState({
          approvalSubmitting: false,
          approveReason: res.approve_reason,
          currentButtonArea: 'approved'
        });
      },
      error => {
        const approvalError =
          error.response.data.error ||
          error.response.data.errors.join('\n') ||
          InAdvanceApplicationDetail.approvalErrorMessage();
        this.setState({
          approvalSubmitting: false,
          approvalError
        });
      }
    );
  }

  handleCancelApproval(e: React.MouseEvent<HTMLElement>) {
    e.preventDefault();
    this.setState({ currentButtonArea: 'normal' });
  }

  handleSubmitRejection(e: React.MouseEvent<HTMLElement>) {
    e.preventDefault();
    if (this.state.rejectReason.length === 0) {
      this.setState({ rejectError: '却下する理由を入力してください' });
    } else {
      this.setState({
        rejectSubmitting: true,
        rejectError: null
      });
      Fetcher.put<{ reject_reason: string }>(`/application/in_advance/${this.state.trip.id}/reject`, {
        reject_reason: this.state.rejectReason
      }).then(
        res => {
          this.setState({
            rejectSubmitting: false,
            rejectReason: res.reject_reason,
            currentButtonArea: 'rejected'
          });
        },
        error => {
          this.setState({
            rejectSubmitting: false,
            rejectError:
              error.response.data.error ||
              error.response.data.errors.join('\n') ||
              '通信に失敗しました。しばらくしてから再度お試しください。'
          });
        }
      );
    }
  }

  handleCancelRejection(e: React.MouseEvent<HTMLElement>) {
    e.preventDefault();
    this.setState({ currentButtonArea: 'normal' });
  }

  handleRejectReasonChange(e: React.ChangeEvent<HTMLTextAreaElement>) {
    e.preventDefault();
    this.setState({ rejectReason: e.target.value });
  }

  handleApproveReasonChange(e: React.ChangeEvent<HTMLTextAreaElement>) {
    e.preventDefault();
    this.setState({ approveReason: e.target.value });
  }

  monthBudgetRate() {
    return (
      Math.round(
        (utils.dig(this.state.budgetInfo, 'this_month_cost') /
          utils.dig(this.state.budgetInfo, 'monthly_budget')) *
          100 *
          10
      ) / 10
    );
  }

  termBudgetRate() {
    return (
      Math.round(
        (utils.dig(this.state.budgetInfo, 'this_term_cost') /
          (utils.dig(this.state.budgetInfo, 'monthly_budget') * 12)) *
          100 *
          10
      ) / 10
    );
  }

  nomalContent(item: any, traveler: any, total: number, type: string, itemName: string) {
    const orderItemMappings = item.orderItemMappings.findByTravelerInformationAndOrderItem(traveler.id, item.id);
    if (orderItemMappings == null) {
      return;
    }
    if (type === 'department' && orderItemMappings.chargingDepartmentShareId != null) {
      return;
    }
    if (type === 'project' && orderItemMappings.projectShareId != null) {
      return;
    }

    const price = item.price.price + (item.price.margin.amount ? item.price.margin.amount : 0);
    const amount = price / (total || 1);
    // eslint-disable-next-line consistent-return
    return this.contentInfo(item, traveler, amount, itemName, traveler.id);
  }

  contentForNonOrderItem(item: any, traveler: any, total: number, type: string, itemName: string) {
    const nonOrderItemMappings = item.nonOrderItemMappings.find((o: any) => o.nonOrderItemId === item.id);
    if (nonOrderItemMappings == null) {
      return;
    }

    if (type === 'department' && nonOrderItemMappings.chargingDepartmentShareId != null) {
      return;
    }
    if (type === 'project' && nonOrderItemMappings.projectShareId != null) {
      return;
    }
    const price = item.price.price || 0;
    const amount = price / (total || 1);

    // eslint-disable-next-line consistent-return
    return this.contentInfo(item, traveler, amount, itemName, traveler.id);
  }

  findNonOrderItemMapping(nonOrderItemMappings: any, travelerId: any, nonOrderItemId: any) {
    return nonOrderItemMappings.find(
      (o: NonOrderItemMapping) => o.travelerInformationId === travelerId && o.nonOrderItemId === nonOrderItemId
    );
  }

  contentInfo(item: any, traveler: any, price: number, itemName: string, index: number) {
    return (
      <TableRow key={index}>
        <StyledTableCell>{itemName}</StyledTableCell>
        <StyledTableCell>
          {item.elements.map((el: any, j: number) => (
            <React.Fragment key={j}>
              {el.name && <ElementHeader>{el.name}</ElementHeader>}
              <TranslateIgnoreText text={el.summary()} />
            </React.Fragment>
          ))}
        </StyledTableCell>
        <StyledTableCell>
          {item.elements.map((el: any, j: number) => (
            <React.Fragment key={j}>{el.mappingDescription()?.split('：')[0] || ''}</React.Fragment>
          ))}
        </StyledTableCell>
        <StyledTableCell>{traveler.name}</StyledTableCell>
        <StyledTableCellRight>
          <span data-wovn-ignore>{utils.digits(price)}</span>
          <span>円</span>
        </StyledTableCellRight>
      </TableRow>
    );
  }

  chargingShareContent(item: any, traveler: any, total: number, type: string, itemName: string) {
    const orderItemMappings = item.orderItemMappings.findByTravelerInformationAndOrderItem(traveler.id, item.id);
    if (orderItemMappings == null) {
      return;
    }

    let shareList;
    if (type === 'department') {
      const chargingDepartmentShare = orderItemMappings?.chargingDepartmentShare;
      if (chargingDepartmentShare == null || orderItemMappings.chargingDepartmentShareId == null) {
        return;
      }
      shareList = chargingDepartmentShare?.chargingDepartmentShareMappings.list;
    }
    if (type === 'project') {
      const projectShare = orderItemMappings?.projectShare;
      if (projectShare == null || orderItemMappings.projectShareId == null) {
        return;
      }
      shareList = projectShare?.projectShareMappings.list;
    }

    const price = item.price.price + (item.price.margin.amount ? item.price.margin.amount : 0);
    const amount = price / (total || 1);
    let sumWeight = 0;
    // eslint-disable-next-line array-callback-return
    shareList.map((mapping: any) => {
      sumWeight += mapping.weight;
    });

    // eslint-disable-next-line consistent-return
    return (
      <>
        {shareList.map((mapping: any) => {
          const proportionalPrice = amount * (mapping.weight / sumWeight);
          return this.contentInfo(item, traveler, Math.round(proportionalPrice), itemName, mapping.id);
        })}
      </>
    );
  }

  setTravelerName(traveler: any) {
    if (traveler.user_name !== null && traveler.user_name !== '') {
      return traveler.user_name;
    }

    if (
      traveler.last_name_kana !== null &&
      traveler.last_name_kana !== '' &&
      traveler.first_name_kana !== null &&
      traveler.first_name_kana !== ''
    ) {
      return `${traveler.last_name_kana} ${traveler.first_name_kana}`;
    }
    return `${traveler.last_name_roman} ${traveler.first_name_roman}`;
  }

  marketLogPath(element: ElementBase) {
    return MarketLogHelper.elementMarketLogPath(element, this.state.trip.id);
  }

  static approvalErrorMessage() {
    return `申し訳ございません。ご予約を受け付けることができませんでした。

    時間を空けてもう一度お試しいただき、改善されない場合は
    下記お問い合わせフォームよりご連絡ください。

    https://${location.hostname}/inquiries/new`;
  }

  handleShowAlert = (showAlert: boolean) => {
    this.setState({ showAlert });
  };

  render() {
    try {
      const {
        trip,
        budgetInfo,
        loading,
        error,
        hotelError,
        transportError,
        shinkansenTooLateError,
        shinkansenDeadline,
        domesticAirTooLateError,
        domesticAirDeadline,
        foreignAirTooLateError,
        foreignAirDeadline,
        packageTooLateError,
        packageDeadline,
        rentalCarTooLateError,
        rentalCarDeadline,
        hotelTooLateError,
        hotelDeadline,
        currentButtonArea,
        approvalSubmitting,
        approvalError,
        rejectSubmitting,
        approveReason,
        rejectReason,
        rejectError,
        approveItems,
        shinkansenAddressInfo,
        projects,
        projectShares,
        departments,
        departmentShares,
        expensesAccountTypes,
        expensesAccountTypeAvailable,
        domesticAirTicketInfo,
        tripDuplications,
        showAlert
      } = this.state;
      const { theme, approved } = this.props;

      const isApproved = approved === undefined ? currentButtonArea === 'approved' : approved;
      return (
        <ApplicationDetailWrap>
          <ApplicationDetail>
            <ApplicationDetailIn>
              <SectionHeaderTitle>
                <ContentHeader
                  title="出張の事前申請"
                  backButton
                  customTitlePosition
                  customWrapPadding="0"
                  customFlexBasis="200px"
                />
              </SectionHeaderTitle>

              <Body>
                <BodyIn>
                  {loading ? (
                    <SimpleLoading />
                  ) : error ? (
                    <Error>{error}</Error>
                  ) : trip ? (
                    <>
                      {tripDuplications.length ? (
                        <>
                          {tripDuplications.map((duplication, i) => (
                            <TripDuplicationSection
                              key={i}
                              tripDuplication={duplication}
                              apiProps={{
                                sendLog: i < 1 && !isApproved,
                                searchQueryId: trip.searchQueryId,
                                tripId: trip.id,
                                path: `/application/in_advance/${trip.id}`,
                                tripDuplications
                              }}
                            />
                          ))}
                        </>
                      ) : null}
                      <div>下記、出張を申請します。</div>
                      <OutlineBox>
                        <SectionApplication>
                          <SectionApplicationInfo>
                            <InfoSection>
                              <ApplicationInfoLabel>申請者</ApplicationInfoLabel>
                              <ApplicationInfoText data-wovn-ignore>{trip.user.name}</ApplicationInfoText>
                            </InfoSection>
                            {trip.traveler_informations.map((info: any, n: number) => (
                              <InfoSection key={n}>
                                <ApplicationInfoLabel>出張者{`${n + 1}`}</ApplicationInfoLabel>
                                <ApplicationInfoText data-wovn-ignore>
                                  {this.setTravelerName(info)}
                                </ApplicationInfoText>
                              </InfoSection>
                            ))}
                            {trip.in_advance_approval.map((aa: any, n: number) => (
                              <InfoSection key={n}>
                                {(trip.isApproved(n) || trip.isRejected(n)) && (
                                  <>
                                    <ApplicationInfoLabel>{`${aa.approve_stage}次承認者`}</ApplicationInfoLabel>
                                    <ApplicationInfoText>{`${trip.approvedName(n)}`}</ApplicationInfoText>
                                  </>
                                )}
                              </InfoSection>
                            ))}
                            {trip.nextStageApprovers(
                              trip.in_advance_approval[trip.in_advance_approval.length - 1].approve_stage
                            ) && (
                              <InfoSection>
                                <ApplicationInfoLabel>次の承認担当者</ApplicationInfoLabel>
                                <ApplicationInfoText data-wovn-ignore>
                                  {`${trip
                                    .nextStageApprovers(
                                      trip.in_advance_approval[trip.in_advance_approval.length - 1].approve_stage
                                    )
                                    .join(', ')}`}
                                </ApplicationInfoText>
                              </InfoSection>
                            )}

                            <InfoSection>
                              <ApplicationInfoLabel>出張期間</ApplicationInfoLabel>
                              <ApplicationInfoText data-wovn-ignore>{trip.dateRange()}</ApplicationInfoText>
                            </InfoSection>
                            <InfoSection>
                              <ApplicationInfoLabel>出張人数</ApplicationInfoLabel>
                              <ApplicationInfoText data-wovn-ignore>{trip.peoplenum}名</ApplicationInfoText>
                            </InfoSection>
                            <InfoSection>
                              <ApplicationInfoLabel>申請日</ApplicationInfoLabel>
                              <ApplicationInfoText data-wovn-ignore>{trip.createdAt()}</ApplicationInfoText>
                            </InfoSection>
                            {trip.flightApproveExpiredAt !== null && (
                              <InfoSection>
                                <ApplicationInfoLabel>承認期限</ApplicationInfoLabel>
                                <ApplicationInfoText data-wovn-ignore>
                                  {trip.flightApproveExpiredAt}
                                </ApplicationInfoText>
                              </InfoSection>
                            )}
                            <InfoSection>
                              <ApplicationInfoLabel>出張先</ApplicationInfoLabel>
                              <ApplicationInfoText data-wovn-ignore>{trip.final_destination}</ApplicationInfoText>
                            </InfoSection>
                            <InfoSection>
                              <ApplicationInfoLabel>出張の目的</ApplicationInfoLabel>
                              <ApplicationInfoText data-wovn-ignore>{trip.purpose}</ApplicationInfoText>
                            </InfoSection>
                            {trip.internal_number && (
                              <InfoSection>
                                <ApplicationInfoLabel>社内管理番号</ApplicationInfoLabel>
                                <ApplicationInfoText data-wovn-ignore>{trip.internal_number}</ApplicationInfoText>
                              </InfoSection>
                            )}

                            {/* custom申請項目 */}
                            {approveItems !== null &&
                              approveItems.map((d: any, index: number) => {
                                if (d.dataType === 'label') {
                                  return <React.Fragment key={`label${index.toString()}`} />;
                                }
                                return (
                                  <InfoSection key={d.id}>
                                    <ApplicationInfoLabel>{d.userDisplayName}</ApplicationInfoLabel>
                                    {d.dataType !== 'calendar' && (
                                      <>
                                        {d.dataType !== 'file' ? (
                                          <ApplicationInfoText>
                                            {d.valueCode ? (
                                              <TranslateIgnoreText text={`${d.valueCode}：${d.value}`} />
                                            ) : (
                                              <TranslateIgnoreText text={d.value} />
                                            )}
                                          </ApplicationInfoText>
                                        ) : (
                                          <ApplicationInfoText>
                                            {d.filePath ? (
                                              <a
                                                href={`/trips/${this.state.trip.id}/approve_item_files/${d.id}`}
                                                target="_blank"
                                                rel="noreferrer"
                                              >
                                                <FileLinkIcon src="/images/clip_highlighted.png" />
                                              </a>
                                            ) : d.fileType ? (
                                              <a
                                                href={`/trips/${this.state.trip.id}/approve_item_files_base64/${d.id}`}
                                                target="_blank"
                                                rel="noreferrer"
                                              >
                                                <FileLinkIcon src="/images/clip_highlighted.png" />
                                              </a>
                                            ) : null}
                                          </ApplicationInfoText>
                                        )}
                                      </>
                                    )}
                                    {d.dataType === 'calendar' && (
                                      <ApplicationInfoText>
                                        <TranslateIgnoreText
                                          text={d.value ? moment(d.value).format('MM月DD日') : ''}
                                        />
                                      </ApplicationInfoText>
                                    )}
                                  </InfoSection>
                                );
                              })}
                          </SectionApplicationInfo>
                          <SectionPrice>
                            <span>申請金額</span>
                            <Price>
                              {/*
                                リクエストフォームより旅程作成の申請があり、手配オペレータが旅程が組んだ後に承認が行われる場合がある。
                                この場合は、常に金額差分がない形で画面上では表現したい。
                              */}
                              {trip.arrangement_request ? (
                                <PriceText isOld={false}>
                                  <span data-wovn-ignore>{utils.digits(trip.totalPrice())}</span>
                                  <span>円</span>
                                </PriceText>
                              ) : (
                                <>
                                  <PriceText data-wovn-ignore isOld={trip.didTotalPriceChange()}>
                                    <span data-wovn-ignore>{utils.digits(trip.oldTotalPrice())}</span>
                                    <span>円</span>
                                  </PriceText>
                                  {(trip.didTotalPriceChange() || domesticAirTicketInfo.priceDiff > 0) && (
                                    <>
                                      <NewPriceText>
                                        <span data-wovn-ignore>
                                          {utils.digits(trip.totalPrice() + domesticAirTicketInfo.priceDiff)}
                                        </span>
                                        <span>円</span>
                                      </NewPriceText>
                                      <PriceDesc>申請時から値段が変わっています</PriceDesc>
                                    </>
                                  )}
                                </>
                              )}
                            </Price>
                          </SectionPrice>
                        </SectionApplication>
                      </OutlineBox>

                      {trip.ruleErrors.length > 0 && (
                        <ReserveErrorAlert icon={<ErrorOutlineIcon />} variant="filled" severity="error">
                          <StyledAlertTitle>※{trip.ruleErrors.length}件の規程違反があります</StyledAlertTitle>
                          <Flex>
                            <div>
                              {trip.ruleErrors.map((tripRuleError: TripRuleError, i: number) => (
                                <TripRuleErrorSection
                                  key={i}
                                  tripRuleError={tripRuleError}
                                  showAlert={showAlert}
                                />
                              ))}
                            </div>
                            {trip.ruleErrors.some(
                              (rule_error: TripRuleError) => !_.isEmpty(rule_error.detail.lines)
                            ) && (
                              <DescriptionButton onClick={() => this.handleShowAlert(!showAlert)}>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="15"
                                  viewBox="0 0 20 20"
                                  fill="none"
                                  stroke="#666666"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                >
                                  <path d={showAlert ? 'M18 15l-6-6-6 6' : 'M6 9l6 6 6-6'} />
                                </svg>
                              </DescriptionButton>
                            )}
                          </Flex>
                        </ReserveErrorAlert>
                      )}
                      {trip.ruleErrors.length === 0 && trip.ruleDistanceTimeInfo.length > 0 && (
                        <ReserveSuccessAlert icon={<InfoOutlined />} variant="filled" severity="info">
                          <StyledAlertTitle>※旅費規程の範囲内です</StyledAlertTitle>
                          <Flex>
                            <div>
                              {trip.ruleDistanceTimeInfo.map((info: TripRuleDistanceTimeInfo, i: number) => (
                                <TripRuleSuccessSection
                                  key={i}
                                  tripRuleDistanceTimeInfo={info}
                                  showAlert={showAlert}
                                />
                              ))}
                            </div>
                            {trip.ruleDistanceTimeInfo[0].detail.lines?.length && (
                              <DescriptionButton onClick={() => this.handleShowAlert(!showAlert)}>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="15"
                                  viewBox="0 0 20 20"
                                  fill="none"
                                  stroke="#666666"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                >
                                  <path d={showAlert ? 'M18 15l-6-6-6 6' : 'M6 9l6 6 6-6'} />
                                </svg>
                              </DescriptionButton>
                            )}
                          </Flex>
                        </ReserveSuccessAlert>
                      )}

                      {(trip.isDepartmentMappingsExist() || trip.isDepartmentNonOrderItemMappingsExist()) && (
                        <section>
                          <SectionTitle>費用負担部署別の金額内訳</SectionTitle>
                          <StyledTable>
                            <StyledTableHead>
                              <TableRow>
                                <Th>部署</Th>
                                <Th minWidth="40%">詳細</Th>
                                <Th minWidth="15%">区分</Th>
                                <Th minWidth="15%">出張者</Th>
                                <Th minWidth="15%">料金</Th>
                              </TableRow>
                            </StyledTableHead>
                            <TableBody>
                              {trip.order.orderItems.map((item: any) => (
                                <React.Fragment key={item.id}>
                                  {item.travelerInformations.map((traveler: any, index: number) => (
                                    <React.Fragment key={index}>
                                      {this.nomalContent(
                                        item,
                                        traveler,
                                        item.travelerInformations.length,
                                        'department',
                                        item.orderItemMappings
                                          .findByTravelerInformationAndOrderItem(traveler.id, item.id)
                                          ?.chargingDepartmentDescription(departments, departmentShares)
                                      )}
                                      {this.chargingShareContent(
                                        item,
                                        traveler,
                                        item.travelerInformations.length,
                                        'department',
                                        item.orderItemMappings
                                          .findByTravelerInformationAndOrderItem(traveler.id, item.id)
                                          ?.chargingDepartmentDescription(departments, departmentShares)
                                      )}
                                    </React.Fragment>
                                  ))}
                                </React.Fragment>
                              ))}
                              {trip.nonOrderItems.map((item: any) => (
                                <React.Fragment key={item.id}>
                                  {trip.travelerInformations.list.map((traveler: any, index: number) => (
                                    <React.Fragment key={index}>
                                      {this.contentForNonOrderItem(
                                        item,
                                        traveler,
                                        trip.travelerInformations.list.length,
                                        'department',
                                        (() => {
                                          const mapping =
                                            this.findNonOrderItemMapping(
                                              item.nonOrderItemMappings,
                                              traveler.id,
                                              item.id
                                            ) || [];
                                          const target =
                                            departments.list.find(
                                              (department: Department) =>
                                                department.id === mapping.chargingDepartmentId
                                            ) || null;
                                          return target ? `${target?.code || ''} : ${target.name || ''}` : '';
                                        })()
                                      )}
                                    </React.Fragment>
                                  ))}
                                </React.Fragment>
                              ))}
                            </TableBody>
                          </StyledTable>
                        </section>
                      )}

                      {(trip.isProjectMappingsExist() || trip.isProjectNonOrderItemMappingsExist()) && (
                        <section>
                          <SectionTitle>プロジェクト別の金額内訳</SectionTitle>
                          <StyledTable>
                            <StyledTableHead>
                              <TableRow>
                                <Th>項目</Th>
                                <Th minWidth="40%">詳細</Th>
                                <Th minWidth="15%">区分</Th>
                                <Th minWidth="15%">出張者</Th>
                                <Th minWidth="15%">料金</Th>
                              </TableRow>
                            </StyledTableHead>
                            <TableBody>
                              {trip.order.orderItems.map((item: any) => (
                                <React.Fragment key={item.id}>
                                  {item.travelerInformations.map((traveler: any, index: number) => (
                                    <React.Fragment key={index}>
                                      {this.nomalContent(
                                        item,
                                        traveler,
                                        item.travelerInformations.length,
                                        'project',
                                        item.orderItemMappings
                                          .findByTravelerInformationAndOrderItem(traveler.id, item.id)
                                          ?.projectDescription(projects, projectShares)
                                      )}
                                      {this.chargingShareContent(
                                        item,
                                        traveler,
                                        item.travelerInformations.length,
                                        'project',
                                        item.orderItemMappings
                                          .findByTravelerInformationAndOrderItem(traveler.id, item.id)
                                          ?.projectDescription(projects, projectShares)
                                      )}
                                    </React.Fragment>
                                  ))}
                                </React.Fragment>
                              ))}
                              {trip.nonOrderItems.map((item: any) => (
                                <React.Fragment key={item.id}>
                                  {trip.travelerInformations.list.map((traveler: any, index: number) => (
                                    <React.Fragment key={index}>
                                      {this.contentForNonOrderItem(
                                        item,
                                        traveler,
                                        trip.travelerInformations.list.length,
                                        'project',
                                        (() => {
                                          const mapping =
                                            this.findNonOrderItemMapping(
                                              item.nonOrderItemMappings,
                                              traveler.id,
                                              item.id
                                            ) || [];
                                          const project = projects.find(mapping.projectId?.toString()) || null;
                                          return project ? `${project.code} : ${project.name}` : '';
                                        })()
                                      )}
                                    </React.Fragment>
                                  ))}
                                </React.Fragment>
                              ))}
                            </TableBody>
                          </StyledTable>
                        </section>
                      )}

                      {expensesAccountTypeAvailable &&
                        (trip.isExpensesAccountTypeMappingsExist() ||
                          trip.isExpensesAccountTypeNonOrderItemMappingsExist()) && (
                          <section>
                            <SectionTitle>勘定科目別の金額内訳</SectionTitle>
                            <StyledTable>
                              <StyledTableHead>
                                <TableRow>
                                  <Th>項目</Th>
                                  <Th minWidth="40%">詳細</Th>
                                  <Th minWidth="15%">区分</Th>
                                  <Th minWidth="15%">出張者</Th>
                                  <Th minWidth="15%">料金</Th>
                                </TableRow>
                              </StyledTableHead>
                              <TableBody>
                                {trip.order.orderItems.map((item: any, itemIdx: number) => (
                                  <React.Fragment key={item.id}>
                                    {item.travelerInformations.map((traveler: any, index: number) => (
                                      <React.Fragment key={index}>
                                        {this.nomalContent(
                                          item,
                                          traveler,
                                          item.travelerInformations.length,
                                          'account',
                                          item.orderItemMappings
                                            .findByTravelerInformationAndOrderItem(traveler.id, item.id)
                                            ?.expensesAccountTypeDescription(expensesAccountTypes)
                                        )}
                                      </React.Fragment>
                                    ))}
                                  </React.Fragment>
                                ))}
                                {trip.nonOrderItems.map((item: any) => (
                                  <React.Fragment key={item.id}>
                                    {trip.travelerInformations.list.map((traveler: any, index: number) => (
                                      <React.Fragment key={index}>
                                        {this.contentForNonOrderItem(
                                          item,
                                          traveler,
                                          trip.travelerInformations.list.length,
                                          'account',
                                          (() => {
                                            const mapping =
                                              this.findNonOrderItemMapping(
                                                item.nonOrderItemMappings,
                                                traveler.id,
                                                item.id
                                              ) || [];
                                            const target =
                                              expensesAccountTypes.find(
                                                (list: any) => list.id === mapping.expensesAccountTypeId
                                              ) || null;
                                            return target ? `${target?.code || ''} : ${target.name || ''}` : '';
                                          })()
                                        )}
                                      </React.Fragment>
                                    ))}
                                  </React.Fragment>
                                ))}
                              </TableBody>
                            </StyledTable>
                          </section>
                        )}

                      {/* 旅程の詳細 */}
                      <InAdvanceApplicationTripDetail
                        theme={theme}
                        trip={trip}
                        transportVacancies={domesticAirTicketInfo.transportVacancies}
                      />
                      {utils.dig(budgetInfo, 'monthly_budget') && (
                        <>
                          <SectionBudgetHr />
                          <SectionApplication>
                            <SectionBudgetTitle>予算消化状況</SectionBudgetTitle>
                            <SectionBudgetInfo>
                              <InfoSection>
                                <BudgetInfoLabel>年次予算消化状況</BudgetInfoLabel>
                                <BudgetInfoCost data-wovn-ignore>
                                  {utils.digits(utils.dig(budgetInfo, 'this_term_cost'))}
                                </BudgetInfoCost>
                                <span>円</span>
                                <span>/</span>
                                <span data-wovn-ignore>
                                  {utils.digits(utils.dig(budgetInfo, 'monthly_budget') * 12)}
                                </span>
                                <span>円</span>
                                <BudgetInfoPercent costOver={this.termBudgetRate() > 100} data-wovn-ignore>
                                  {this.termBudgetRate()}%
                                </BudgetInfoPercent>
                              </InfoSection>
                              <InfoSection>
                                <BudgetInfoLabel>月次予算消化状況</BudgetInfoLabel>
                                <BudgetInfoCost data-wovn-ignore>
                                  {utils.digits(utils.dig(budgetInfo, 'this_month_cost'))}
                                </BudgetInfoCost>
                                <span>円</span>
                                <span>/</span>
                                <span data-wovn-ignore>
                                  {utils.digits(utils.dig(budgetInfo, 'monthly_budget'))}
                                </span>
                                <span>円</span>
                                <BudgetInfoPercent costOver={this.monthBudgetRate() > 100} data-wovn-ignore>
                                  {this.monthBudgetRate()}%
                                </BudgetInfoPercent>
                              </InfoSection>
                            </SectionBudgetInfo>
                          </SectionApplication>
                        </>
                      )}
                      {(utils.dig(shinkansenAddressInfo, 'shinkansen_address_postcode') ||
                        utils.dig(shinkansenAddressInfo, 'shinkansen_address') ||
                        utils.dig(shinkansenAddressInfo, 'shinkansen_addressee')) && (
                        <>
                          <SectionBudgetHr />
                          <SectionApplication>
                            <SectionBudgetTitle>送付先住所</SectionBudgetTitle>
                            <SectionBudgetInfo>
                              <AddressInfoLabel>
                                <div>{utils.dig(shinkansenAddressInfo, 'shinkansen_address_postcode')}</div>
                                <div>{utils.dig(shinkansenAddressInfo, 'shinkansen_address')}</div>
                                <div>{utils.dig(shinkansenAddressInfo, 'shinkansen_addressee')}</div>
                              </AddressInfoLabel>
                            </SectionBudgetInfo>
                          </SectionApplication>
                        </>
                      )}
                      <SectionBudgetHr />
                      <SectionApplication>
                        <SectionBudgetTitle>承認フロー</SectionBudgetTitle>
                        <StatusWrap>
                          {this.state.inAdvanceApproval.map((approval: InAdvanceApproval, i: number) => (
                            <StatusItems key={i}>
                              <StatusItem
                                key={i}
                                approved={approval.status === 1}
                                prevApproved={i > 0 && this.state.inAdvanceApproval[i - 1].status === 1}
                                prevReject={
                                  i > 0 &&
                                  this.state.inAdvanceApproval[i - 1].status === 2 &&
                                  Boolean(this.state.inAdvanceApproval[i - 1].rejectedAt)
                                }
                                first={i === 0}
                              >
                                <StatusIcon approved={approval.status === 1 || approval.status === 2}>
                                  <svg
                                    viewBox="0 0 22 22"
                                    version="1.1"
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                  >
                                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                      <Circle
                                        approved={approval.status === 1}
                                        strokeWidth="6"
                                        cx="11"
                                        cy="11"
                                        r="8"
                                      />
                                    </g>
                                  </svg>
                                </StatusIcon>
                                <StatusText>
                                  <StatusColor status={approval.status}>{approval.statusName()}</StatusColor>
                                  <>
                                    {approval.inAdvanceApproverCandidates?.length > 0 &&
                                      approval.inAdvanceApproverCandidates
                                        .map(candidate => candidate?.approverCandidate?.name || '')
                                        .join('、')}
                                  </>
                                  {approval.status === 1 && approval.approvedAt && (
                                    <ApprovalWrapper>
                                      <ApprovalAt>
                                        (対応日：{approval.approvedAt.format('YYYY/MM/DD HH:mm')})
                                      </ApprovalAt>
                                      <div>{approval.approveReason || '　'}</div>
                                    </ApprovalWrapper>
                                  )}
                                  {approval.status === 2 && approval.rejectedAt && (
                                    <ApprovalWrapper>
                                      <ApprovalAt>
                                        (対応日：{approval.rejectedAt.format('YYYY/MM/DD HH:mm')})
                                      </ApprovalAt>
                                    </ApprovalWrapper>
                                  )}
                                </StatusText>
                              </StatusItem>
                            </StatusItems>
                          ))}
                        </StatusWrap>
                      </SectionApplication>
                      <SectionBudgetHr />

                      <ButtonArea>
                        {!isApproved && (
                          <>
                            {trip.isHotelCheckinExpired() ? (
                              <ButtonAreaError>
                                ご指定のホテル・部屋タイプはチェックイン日を過ぎています。恐れ入りますが、一度却下して再申請を依頼してください。
                              </ButtonAreaError>
                            ) : hotelError ? (
                              <ButtonAreaError>
                                ご指定のホテル・部屋タイプの空きがなくなりました。恐れ入りますが、一度却下して再申請を依頼してください。
                              </ButtonAreaError>
                            ) : hotelTooLateError ? (
                              <ButtonAreaError>
                                {`ご指定のホテルの手配は${hotelDeadline}が依頼期限です。恐れ入りますが、この申請は却下してください。`}
                              </ButtonAreaError>
                            ) : (
                              <></>
                            )}
                            {transportError && !foreignAirTooLateError && (
                              <ButtonAreaError>
                                ご指定の交通機関の空席がなくなりました。恐れ入りますが、一度却下して再申請を依頼してください。
                              </ButtonAreaError>
                            )}
                            {shinkansenTooLateError &&
                              (utils.isTabikobo(trip.user.organization.service_id) ? (
                                <ButtonAreaError>{`${shinkansenDeadline}が承認期限です。恐れ入りますが、この申請は却下してください。`}</ButtonAreaError>
                              ) : (
                                <ButtonAreaError>{`新幹線・特急チケットは、${shinkansenDeadline}が承認期限です。恐れ入りますが、この申請は却下してください。`}</ButtonAreaError>
                              ))}
                            {domesticAirTooLateError && (
                              <ButtonAreaError>{`国内航空券は${domesticAirDeadline}が承認期限です。恐れ入りますが、この申請は却下してください。`}</ButtonAreaError>
                            )}
                            {packageTooLateError && (
                              <ButtonAreaError>{`パッケージは${packageDeadline}が承認期限です。恐れ入りますが、この申請は却下してください。`}</ButtonAreaError>
                            )}
                            {rentalCarTooLateError && (
                              <ButtonAreaError>{`レンタカーは${rentalCarDeadline}が承認期限です。恐れ入りますが、この申請は却下してください。`}</ButtonAreaError>
                            )}
                            {trip.flightTooLateError() ? (
                              <ButtonAreaError>{`この航空券は${trip.flightApproveExpiredAt}が承認期限です。恐れ入りますが、この申請は却下してください。`}</ButtonAreaError>
                            ) : (
                              foreignAirTooLateError && (
                                <ButtonAreaError>{`この航空券は${foreignAirDeadline}が承認期限です。恐れ入りますが、この申請は却下してください。`}</ButtonAreaError>
                              )
                            )}
                            {domesticAirTicketInfo.status === 'invalid' && (
                              <ButtonAreaError>
                                ご指定の航空券が満席になりました。恐れ入りますが、一度却下して再申請を依頼してください。
                              </ButtonAreaError>
                            )}
                          </>
                        )}
                        {trip.isBackStageApprover(trip.currentUser.id) && (
                          <ButtonAreaError>
                            現在承認できません、前の承認者が承認してからアクセスしてください。
                          </ButtonAreaError>
                        )}
                        {domesticAirTicketInfo.status === 'loading' && (
                          <ButtonAreaLoading>
                            <Loading size="small" />
                            最新の価格を取得しています。しばらくお待ちください。
                          </ButtonAreaLoading>
                        )}
                        {!_.isEmpty(trip.isApproveCanceled()) ? (
                          <>
                            <ButtonAreaError>取り下げられました</ButtonAreaError>
                            <CancelReason>■理由</CancelReason>
                            <p>{trip.cancelReason()}</p>
                          </>
                        ) : currentButtonArea === 'normal' ? (
                          <Buttons>
                            <Button
                              buttonType={ButtonType.POSITIVE}
                              className="in-advance-application-detail__approve-button"
                              onClick={e => this.handleApproveClick(e)}
                              disabled={
                                trip.isHotelCheckinExpired() ||
                                hotelError ||
                                transportError ||
                                shinkansenTooLateError ||
                                hotelTooLateError ||
                                domesticAirTooLateError ||
                                packageTooLateError ||
                                rentalCarTooLateError ||
                                trip.isBackStageApprover(trip.currentUser.id) ||
                                trip.flightTooLateError() ||
                                ['loading', 'invalid'].includes(domesticAirTicketInfo.status)
                              }
                            >
                              承認
                            </Button>
                            <Button
                              buttonType={ButtonType.DANGER}
                              onClick={e => this.handleRejectClick(e)}
                              disabled={trip.isBackStageApprover(trip.currentUser.id)}
                            >
                              却下
                            </Button>
                          </Buttons>
                        ) : currentButtonArea === 'approving' ? (
                          <>
                            {approvalSubmitting ? (
                              <SimpleLoading />
                            ) : (
                              <>
                                {approvalError ? (
                                  <>
                                    <ApprovalError>
                                      {approvalError.split('\n').map((str, i) => {
                                        if (str.includes('https://')) {
                                          return (
                                            <A href={str} target="_blank" rel="noopener noreferrer" key={i}>
                                              {str}
                                              <br />
                                            </A>
                                          );
                                        }
                                        return (
                                          <React.Fragment key={i}>
                                            {str}
                                            <br />
                                          </React.Fragment>
                                        );
                                      })}
                                    </ApprovalError>
                                    <ApprovalButton onClick={() => location.reload()}>再読込</ApprovalButton>
                                  </>
                                ) : (
                                  <>
                                    <Confirm>
                                      上記の出張申請を承認します。
                                      <br />
                                      理由を入力してください（任意）
                                    </Confirm>
                                    <ReasonField
                                      value={approveReason}
                                      onChange={e => this.handleApproveReasonChange(e)}
                                      placeholder="出張を承認する理由"
                                    />

                                    <ApprovalButtons className="in-advance-application-detail__button-area">
                                      <ApprovalButton onClick={e => this.handleSubmitApproval(e)}>
                                        送信
                                      </ApprovalButton>
                                      <ApprovalButton onClick={e => this.handleCancelApproval(e)}>
                                        戻る
                                      </ApprovalButton>
                                    </ApprovalButtons>
                                  </>
                                )}
                              </>
                            )}
                          </>
                        ) : currentButtonArea === 'rejecting' ? (
                          <>
                            {rejectSubmitting ? (
                              <SimpleLoading />
                            ) : (
                              <>
                                <Confirm>
                                  上記の出張申請を却下します。
                                  <br />
                                  理由を入力してください（必須）
                                </Confirm>
                                <ReasonField
                                  value={rejectReason}
                                  onChange={e => this.handleRejectReasonChange(e)}
                                  placeholder="出張を却下する理由"
                                />
                                {rejectError && <ApprovalError>{rejectError}</ApprovalError>}
                                <ApprovalButtons>
                                  <ApprovalButton onClick={e => this.handleSubmitRejection(e)}>
                                    送信
                                  </ApprovalButton>
                                  <ApprovalButton onClick={e => this.handleCancelRejection(e)}>
                                    戻る
                                  </ApprovalButton>
                                </ApprovalButtons>
                              </>
                            )}
                          </>
                        ) : currentButtonArea === 'approved' ? (
                          <>
                            <ButtonAreaSuccess>承認しました</ButtonAreaSuccess>
                            <CancelReason>■理由</CancelReason>
                            <p>
                              {trip.approveReason(trip.in_advance_approval.length - 1) ||
                                approveReason ||
                                '記入なし'}
                            </p>
                            {trip.received_at_in_tokyo && (
                              <p>最終承認日時: {moment(trip.received_at_in_tokyo).format('llll')}</p>
                            )}
                          </>
                        ) : currentButtonArea === 'rejected' ? (
                          <>
                            <ButtonAreaError>却下しました</ButtonAreaError>
                            <CancelReason>■理由</CancelReason>
                            <p>{trip.rejectReason(trip.in_advance_approval.length - 1) || rejectReason}</p>
                          </>
                        ) : currentButtonArea === 'applied' ? (
                          <ButtonAreaWait>この申請は承認待ちです</ButtonAreaWait>
                        ) : (
                          <></>
                        )}
                      </ButtonArea>
                    </>
                  ) : (
                    <></>
                  )}
                </BodyIn>
              </Body>
            </ApplicationDetailIn>
          </ApplicationDetail>
        </ApplicationDetailWrap>
      );
    } catch (e) {
      reportError(e);
      return null;
    }
  }
}

const ApplicationDetailWrap = styled.div`
  min-height: 100vh;
`;

const ApplicationDetail = styled.div`
  padding: 20px 0;
`;

const ApplicationDetailIn = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin: 0 auto;
`;

const Body = styled(ContentBody)`
  max-width: 1150px;
`;

const BodyIn = styled(ContentBodyIn)`
  padding: 40px 60px;

  ${media.sp`
    padding: 14px;
  `}
`;

const SectionHeaderTitle = styled.div`
  max-width: 1150px;
  margin-bottom: 20px;
  width: 100%;
  min-height: 0%;
  margin-left: auto;
  margin-right: auto;
`;

const Error = styled.div`
  color: ${props => props.theme.redColor};
  white-space: pre-line;
`;

const InfoSection = styled.div`
  display: flex;
  align-items: flex-end;
`;

const Price = styled.div`
  font-size: 20px;
  font-weight: bold;

  ${media.sp`
    font-size: 14px;
  `}
`;

const SectionPrice = styled.div`
  background-color: ${props => props.theme.grayBgColorLight};
  padding: 10px;
  font-weight: bold;
  min-height: 56px;
  display: flex;
  width: 40%;
  justify-content: space-between;
  align-items: center;

  ${media.sp`
    width: 100%;
  `}
`;

const PriceText = styled.div<{ isOld: boolean }>`
  ${props =>
    props.isOld &&
    `
    text-decoration: line-through;
  `}
`;

const NewPriceText = styled.div`
  color: ${props => props.theme.redColor};
`;

const PriceDesc = styled.div`
  color: ${props => props.theme.redColor};
  font-size: 10px;
  font-weight: normal;
`;

const SectionTitle = styled.h3`
  margin-top: 40px;
  margin-bottom: 5px;
  padding-bottom: 10px;
  border-bottom: 1px solid ${props => props.theme.grayBorderColor};
`;

const ButtonArea = styled.section`
  margin-top: 40px;
`;

const ButtonAreaSuccess = styled.p`
  color: ${props => props.theme.successColor};
`;

const ButtonAreaError = styled.p`
  color: ${props => props.theme.redColor};
`;

const ButtonAreaWait = styled.p`
  color: ${props => props.theme.grayTextColor};
`;

const ButtonAreaLoading = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 8px;

  div {
    flex-grow: inherit;
  }
`;

const CancelReason = styled.p`
  margin-top: 10px;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: space-around;
`;

const Button = styled.button`
  ${ButtonBase};
  width: 150px;
`;

const Confirm = styled.p`
  font-weight: bold;
  margin-bottom: 10px;
`;

const ApprovalError = styled.p`
  color: ${props => props.theme.redColor};
`;

const ApprovalButtons = styled.div`
  display: flex;
`;

const ApprovalButton = styled.button`
  ${ButtonBase};
  margin-right: 20px;
  border-radius: 50em;
`;

const ReasonField = styled.textarea``;

const FileLinkIcon = styled.img`
  width: 20px;
`;

const OutlineBox = styled.div`
  border: 1px solid ${getColor('border', 'divider')};
  padding: 20px;
  margin: 10px 0;

  ${media.sp`
     padding: 6px;
  `}
`;

const SectionApplication = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  ${media.sp`
    flex-direction: column;
  `}
`;

const SectionApplicationInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 60%;

  ${media.sp`
    width: 100%;
  `}
`;

const ApplicationInfoLabel = styled.div`
  padding: 5px 10px 5px 0;
  font-weight: bold;
  width: 180px;
`;

const ApplicationInfoText = styled.span`
  padding: 5px 0;
  word-break: break-all;
`;

const SectionBudgetHr = styled.hr`
  margin: 15px 0;
  border: 1px solid ${props => props.theme.grayBorderColor};
`;

const SectionBudgetTitle = styled.div`
  min-width: 140px;
  font-weight: bold;
`;

const SectionBudgetInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

const BudgetInfoLabel = styled.span`
  padding: 0 20px;
  font-weight: bold;
`;

const AddressInfoLabel = styled.span`
  padding-left: 20px;
`;

const BudgetInfoCost = styled.span`
  padding-left: 20px;
  font-size: 18px;
  font-weight: bold;

  ${media.sp`
    padding-left: 6px;
  `}
`;
const BudgetInfoPercent = styled.span<{ costOver: boolean }>`
  padding-left: 40px;
  font-weight: bold;
  color: ${props => (props.costOver ? `#DC2626` : `#AAAAAA`)};

  ${media.sp`
    padding-left: 10px;
  `}
`;

const StyledTable = styled(Table)`
  border: 1px solid ${getColor('border', 'divider')};

  ${media.sp`
    display: block;

    .spDisable {
      display: none;
    }

    .MuiTableHead-root {
      display: none;
    }

    .MuiTableBody-root {
      display: flex;
      flex-flow: column;
    }

    .MuiTableRow-root {
      display: flex;
      flex-flow: wrap;
      align-items: center;
      padding: 16px;
      gap: 8px 16px;
      border-bottom: solid 1px #eee;
    }

    .MuiTableCell-root {
      border: 0;
      padding: 0;
      width: 100%;

      &.spWrap {
        width: auto;
      }

      &:last-child {
        padding-top: 8px;
        border-top: solid 1px #eee;
      }

      &:first-child {
        padding-top: 0;
        border-top: 0;
      }
    }
  `}
`;

const StyledTableHead = styled(TableHead)`
  background-color: ${props => props.theme.grayBgColorLight};
  font-weight: bold;
  .MuiTableCell {
    &-head {
      padding: 8px 16px;
      min-width: 120px;
    }
  }

  ${media.sp`
    display: none;
  `}
`;

const StyledTableCell = styled(TableCell)`
  border: 1px solid ${getColor('border', 'divider')};
  padding: 10px 16px;
`;

const StyledTableCellRight = styled(StyledTableCell)`
  border: 1px solid ${getColor('border', 'divider')};
  padding: 10px 16px;
  text-align: right;
`;

const Th = styled.th<{ minWidth?: string }>`
  border: 1px solid ${getColor('border', 'divider')};
  padding: 10px 16px;
  width: ${props => props.minWidth || '50px'};
  text-align: center;
`;

const ElementHeader = styled.div`
  font-weight: bold;
`;

const StatusWrap = styled.div`
  display: flex;
  flex-flow: column;
  flex: 1;
  gap: 1.6em;
  padding: 0;
  ${media.sp`
    padding: 16px 0;
    gap: 16px;
    max-width: 350px;
  `};
`;

const StatusItems = styled.div`
  display: flex;
  flex-flow: column;
  gap: 1.6em;

  ${media.sp`
    padding: 0;
    gap: 16px;
  `};
`;

const StatusItem = styled.div<{ approved: boolean; first: boolean; prevApproved: boolean; prevReject: boolean }>`
  position: relative;
  padding-left: 29px;

  &:before {
    display: block;
    content: '';
    position: absolute;
    left: 9px;
    z-index: 0;
    border-left: solid 3px ${props => props.theme.linkColor};
    height: ${props => (props.prevApproved ? '3.6em' : '1.7em')};
    top: ${props => (props.prevApproved ? '-3.2em' : '-1.6em')};

    ${props =>
      props.prevApproved &&
      media.sp`
      height: 5.1em;
      top: -4.8em;
    `}
    ${props =>
      props.prevReject &&
      media.sp`
      height: 3.6em;
      top: -3.2em;
  `}

    ${props =>
      props.approved &&
      `
      border-color: ${lighten(props.theme.linkColor, 0.6)};
    `}
    ${props =>
      props.first &&
      `
      border-color: transparent;
    `}
  }

  ${media.sp`
    margin-bottom: 6px;
  `}
`;

const StatusIcon = styled.div<{ approved: boolean }>`
  position: absolute;
  top: 1px;
  left: 0;
  z-index: 10;
  width: 21px;
  height: 21px;
`;

const Circle = styled.circle<{ approved: boolean }>`
  stroke: ${props => props.theme.linkColor};

  ${props =>
    props.approved &&
    `
    stroke: ${lighten(props.theme.linkColor, 0.6)};
  `}
`;

const StatusText = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 4px 8px;
`;

const COLORS = {
  gray: '#6B7280',
  orange: '#FB923C',
  green: '#10B981'
};

const getStatusColor = (status: number) => {
  switch (status) {
    case 1:
      return COLORS.green;
    case 0:
      return COLORS.orange;
    default:
      return COLORS.gray;
  }
};

const StatusColor = styled.div<{ status: number }>`
  color: ${props => getStatusColor(props.status)};
  width: 80px;
`;

const ApprovalAt = styled.div`
  color: ${props => props.theme.grayTextColor};
`;

const ApprovalWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export default withTheme(InAdvanceApplicationDetail);
