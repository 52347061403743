import { Fetcher } from '@this/src/util';
import React, { useState, useCallback } from 'react';
import { styled } from '@this/constants/themes';
import type moment from 'moment';
import DatetimePicker from '@this/shared/datetime_picker/datetime_picker';
import { Button } from '@this/src/components/shared/ui/inputs/button';
import { Box } from '@material-ui/core';
import { Text } from '@this/src/components/shared/ui/data_displays/typography';
import type Order from '@this/domain/order';

interface Props {
  orderItemId: number;
  billedAt: moment.Moment | null;
  classNameForModifiedField?: (...path: (string | number)[]) => string;
  onChange?: (billedAt: moment.Moment) => void;
  style?: React.CSSProperties;
  order?: Order;
}

const BilledAtEditor = ({ orderItemId, billedAt, classNameForModifiedField, onChange, style, order }: Props) => {
  const [date, setDate] = useState<moment.Moment | null>(billedAt);
  const [status, setStatus] = useState<'open' | 'closed'>(billedAt ? 'open' : 'closed');
  const [submit, setSubmit] = useState(false);
  const [message, setMessage] = useState('');
  const [showAlert, setShowAlert] = useState('none');
  const [alertMessage, setAlertMessage] = useState('');
  const isRequestedStatus = order?.trip && order.trip.status === 0;
  const alertMessageText = '※旅程ステータスが「要予約」になるまで請求対象日の入力はできません。';
  const handleBilledAt = useCallback(async () => {
    if (isRequestedStatus) {
      setShowAlert('');
      setAlertMessage(alertMessageText);
      return;
    }
    setSubmit(true);
    const args = { billed_at: date && date.toString() };
    Fetcher.put<any>(`/arrangement/order_items/${orderItemId}`, args)
      .then(() => {
        setMessage('保存しました');
        setShowAlert('none');
      })
      .finally(() => {
        setSubmit(false);
      })
      .catch(e => {
        const errorText = e.response.data.errors;
        if (errorText === 'order_item_connected') {
          setShowAlert('');
          setAlertMessage(
            'この商品はすでに請求されているため、請求対象日を空にできません。対象の請求書を取り下げてください。'
          );
        } else if (e.response.status === 400 && errorText) {
          setShowAlert('');
          setAlertMessage(errorText);
        }
        setMessage('保存に失敗しました');
      });
  }, [date, orderItemId, isRequestedStatus]);

  return (
    <>
      <Box display="flex" alignItems="center" style={style}>
        <span
          style={{ marginRight: '5px' }}
          className={classNameForModifiedField ? classNameForModifiedField('billedAt') : ''}
        >
          請求対象日:
        </span>
        {status === 'closed' ? (
          isRequestedStatus ? (
            <Text color="danger">{alertMessageText}</Text>
          ) : (
            <Button
              size="small"
              onClick={() => setStatus('open')}
              style={{ marginLeft: '5px', position: 'initial' }}
            >
              入力する
            </Button>
          )
        ) : isRequestedStatus ? (
          <Text color="danger">{alertMessageText}</Text>
        ) : (
          <>
            {/* 売上基準日が予約確定日のクライアントは、予約確定がなされるまで、この値を編集できてはいけない
              また、billedAtのデフォルトの値は、下記
              売上基準日が予約確定日のクライアント: 予約確定日
              売上基準日が出張完了日のクライアント: 出張完了日
            */}
            <DatetimePicker
              value={date || undefined}
              onChange={d => {
                setDate(d);
                if (onChange) onChange(d);
              }}
              onClear={() => {
                setDate(null);
              }}
              showTime={false}
              disabledDays={0}
              showPast
              border
            />
            <Button
              size="small"
              onClick={() => handleBilledAt()}
              style={{ marginLeft: '5px', position: 'initial' }}
            >
              変更
            </Button>
            {(() => {
              if (submit) {
                return <Message>保存中...</Message>;
              }
              if (message) {
                return <Message>{message}</Message>;
              }
              return <div />;
            })()}
          </>
        )}
      </Box>
      <AlertMessage style={{ display: showAlert }}>{alertMessage}</AlertMessage>
    </>
  );
};

const Message = styled.div`
  margin-left: 5px;
`;

const AlertMessage = styled.div`
  color: red;
`;

export default BilledAtEditor;
